.btn-grey {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 17px;
  background-color: #f0f0f0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-success__active {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.06;
  margin: 0 10px;
  color: #444;
  border-radius: 17px;
  background-color: #80e69b;

  &:hover {
    color: #000;
    background-color: #fff;
    border-color: #80e69b;
  }

  &:active {
    background-color: #80e69b;
    color: #fff;
  }
}

.btn-prf-outline {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 1px 0 0 rgba(60, 64, 67, 0.149);
  transition: box-shadow 0.08s linear, min-width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  font-size: 18px;
  letter-spacing: 0.15px;
  align-items: center;
  background: #fff none;
  border-radius: 24px;
  color: #a6a6a6;
  display: inline-flex;
  height: 48px;
  padding: 0 24px 0 0;
  text-transform: none;
  min-width: 88px;
  cursor: pointer;

  &::before {
    background: url('/profilum-assets/images/control-panel/director/plus-black.svg') no-repeat center;
    content: '';
    display: block;
    border-radius: 50%;
    height: 48px;
    margin-right: 10px;
    width: 48px;
  }
}

.prf-btn-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  width: 100%;
  height: 48px;
  background-color: #3d5adf;
  box-shadow: 0 5px 20px rgba(61, 90, 223, 0.3);
  border-radius: 30px;
  font-family: Avenirnextcyr, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #3D5ADF;
  }

  &:active {
    background: #8B9CEC;
  }

  &_disabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    width: 100%;
    height: 48px;
    background-color: #f5f5f5;
    border-radius: 30px;
    font-family: Avenirnextcyr, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #d3d3d3;
    box-shadow: none;

    &_no-click{
      pointer-events: none;
    }
  }

  &_disabled:hover,
  &_disabled:active {
    background: #f5f5f5;
  }
}

.prf-btn-link {
  font-family: Avenirnextcyr, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3d5adf;
  margin-top: 28px;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #3D5ADF;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:active {
    color: #8B9CEC;
  }
}

.avatar {
  margin: 0 10px 0 0;
  width: 44px;
  height: 44px;
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  background-color: #e2e2e2;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);

  img {
    width: 44px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 20px;
    cursor: pointer;
    font-family: ALSSchlangesans, Arial, sans-serif;
    font-weight: 400;
    margin: 15% 22%;
    color: #fff;
  }

  :hover {
    cursor: pointer;
  }
}

/*===== disabled buttons ======*/
.disabled {
  background-color: #ebebeb;
  pointer-events: none;
  color: rgba(53, 53, 53, 0.5);
  box-shadow: 0 5px 20px 0 rgba(53, 53, 53, 0.3);
}

.disabled-filter {
  pointer-events: none;
  color: rgba(53, 53, 53, 0.5) !important;
}

/*======== BUTTON ANIMATE ON ========*/
.w-center-content {
  text-align: center;
}

.button-animate {
  max-width: 100%;
  min-width: 180px;
  margin-top: 20px;
  text-decoration: none;
  line-height: 48px;
  padding: 0 30px 0 55px;
  position: relative;
  text-align: center;
  display: inline-block;
  background-color: #36ca75;
  color: #fff;
  font-weight: 500;
  border-radius: 23px;
  font-size: 16px;
  transition: all 0.5s linear;
  overflow: hidden;
}

.btn-icon {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 0 solid #36ca75;
  border-radius: 50%;
  display: inline-block;
  top: 11px;
  position: absolute;
  left: 20px;
}

.button-animate .btn-icon::before {
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  transition: all 0.3s linear;
}

.button-animate:hover {
  color: #fff;
}

.btn-activate .btn-icon::before {
  width: 24px;
  height: 24px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI2OC44MzEgMjY4LjgzMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjY4LjgzMSAyNjguODMyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTIyMy4yNTUsODMuNjU5bC04MC03OS45OThjLTQuODgxLTQuODgxLTEyLjc5Ny00Ljg4MS0xNy42NzgsMGwtODAsODBjLTQuODgzLDQuODgyLTQuODgzLDEyLjc5NiwwLDE3LjY3OCAgIGMyLjQzOSwyLjQ0LDUuNjQsMy42NjEsOC44MzksMy42NjFzNi4zOTctMS4yMjEsOC44MzktMy42NjFsNTguNjYxLTU4LjY2MXYyMTMuNjU0YzAsNi45MDMsNS41OTcsMTIuNSwxMi41LDEyLjUgICBjNi45MDEsMCwxMi41LTUuNTk3LDEyLjUtMTIuNVY0Mi42NzdsNTguNjYxLDU4LjY1OWM0Ljg4Myw0Ljg4MSwxMi43OTcsNC44ODEsMTcuNjc4LDAgICBDMjI4LjEzNyw5Ni40NTUsMjI4LjEzNyw4OC41NDEsMjIzLjI1NSw4My42NTl6IiBmaWxsPSIjMzE5YmVmIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
  background-repeat: no-repeat;
  background-size: 10px;
  background-position-x: center;
  background-position-y: center;
}

.button-animate .btn-icon::after {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  transition: all 0.3s linear;
}

.btn-activate .btn-icon::after {
  width: 24px;
  height: 24px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDI2IDI2IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNiAyNiIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCI+CiAgPHBhdGggZD0ibS4zLDE0Yy0wLjItMC4yLTAuMy0wLjUtMC4zLTAuN3MwLjEtMC41IDAuMy0wLjdsMS40LTEuNGMwLjQtMC40IDEtMC40IDEuNCwwbC4xLC4xIDUuNSw1LjljMC4yLDAuMiAwLjUsMC4yIDAuNywwbDEzLjQtMTMuOWgwLjF2LTguODgxNzhlLTE2YzAuNC0wLjQgMS0wLjQgMS40LDBsMS40LDEuNGMwLjQsMC40IDAuNCwxIDAsMS40bDAsMC0xNiwxNi42Yy0wLjIsMC4yLTAuNCwwLjMtMC43LDAuMy0wLjMsMC0wLjUtMC4xLTAuNy0wLjNsLTcuOC04LjQtLjItLjN6IiBmaWxsPSIjMmY4OWQxIi8+Cjwvc3ZnPgo=);
  background-repeat: no-repeat;
  background-size: 8px;
  background-position-x: center;
  background-position-y: 34px;
}

.btn-activate:hover {
  background-color: #36ca75;
}

.btn-activate:hover .btn-icon {
  border-color: #36ca75;
}

.btn-activate:hover .btn-icon::before {
  background-position-y: -34px;
}

.btn-activate:hover .btn-icon::after {
  background-position-y: center;
}

.btn-waiting {
  background-color: #36ca75;
}

.btn-waiting .btn-icon {
  background-color: transparent;
}

.btn-waiting .btn-icon::after {
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: rotation infinite linear 0.5s;
  transition: none;
  border-top: 2px solid transparent;
  border-left: 2px solid #fff;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  z-index: 0;
}

.btn-activated {
  background-color: #36ca75;
  box-shadow: 0 5px 20px 0 rgba(54, 202, 117, 0.3);
}

.btn-activated .btn-icon::after {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDI2IDI2IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNiAyNiIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCI+CiAgPHBhdGggZD0ibS4zLDE0Yy0wLjItMC4yLTAuMy0wLjUtMC4zLTAuN3MwLjEtMC41IDAuMy0wLjdsMS40LTEuNGMwLjQtMC40IDEtMC40IDEuNCwwbC4xLC4xIDUuNSw1LjljMC4yLDAuMiAwLjUsMC4yIDAuNywwbDEzLjQtMTMuOWgwLjF2LTguODgxNzhlLTE2YzAuNC0wLjQgMS0wLjQgMS40LDBsMS40LDEuNGMwLjQsMC40IDAuNCwxIDAsMS40bDAsMC0xNiwxNi42Yy0wLjIsMC4yLTAuNCwwLjMtMC43LDAuMy0wLjMsMC0wLjUtMC4xLTAuNy0wLjNsLTcuOC04LjQtLjItLjN6IiBmaWxsPSIjNDRjYzcxIi8+Cjwvc3ZnPgo=);
  width: 24px;
  height: 24px;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  animation: activated 0.3s linear 1;
}

.btn-text {
  position: relative;
}

.btn-text::before {
  content: attr(data-after);
  position: absolute;
  top: -27px;
  z-index: -1;
  color: #fff;
  transition: all 0.2s linear;
}

.btn-text::after {
  content: attr(data-wait);
  position: absolute;
  top: 2px;
  left: 0;
  z-index: -1;
  color: #fff;
  transition: all 0.2s linear;
}

.btn-waiting .btn-text {
  color: transparent;
}

.btn-waiting .btn-text::after {
  top: -15px;
  z-index: 1;
}

.btn-activated .btn-text::before {
  top: -15px;
  z-index: 1;
}

.btn-activated .btn-text {
  color: transparent;
}

@keyframes rotation {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes activated {
  0% {
    background-position-y: 34px;
  }

  100% {
    background-position-y: center;
  }
}

/*======== BUTTON ANIMATE END ========*/

/*======== BUTTON ANIMATE ON purple ========*/

.button-animate-purple {
  max-width: 100%;
  min-width: 180px;
  margin-top: 20px;
  text-decoration: none;
  line-height: 48px;
  padding: 0 30px 0 55px;
  position: relative;
  text-align: center;
  display: inline-block;
  background-color: #9375fb;
  color: #fff;
  font-weight: 500;
  border-radius: 23px;
  font-size: 16px;
  transition: all 0.5s linear;
  overflow: hidden;
}

.btn-icon-purple {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 0 solid #9375fb;
  border-radius: 50%;
  display: inline-block;
  top: 11px;
  position: absolute;
  left: 20px;
}

.button-animate-purple .btn-icon-purple::before {
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  transition: all 0.3s linear;
}

.button-animate-purple:hover {
  color: #fff;
}

.btn-activate-purple .btn-icon-purple::before {
  width: 24px;
  height: 24px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI2OC44MzEgMjY4LjgzMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjY4LjgzMSAyNjguODMyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTIyMy4yNTUsODMuNjU5bC04MC03OS45OThjLTQuODgxLTQuODgxLTEyLjc5Ny00Ljg4MS0xNy42NzgsMGwtODAsODBjLTQuODgzLDQuODgyLTQuODgzLDEyLjc5NiwwLDE3LjY3OCAgIGMyLjQzOSwyLjQ0LDUuNjQsMy42NjEsOC44MzksMy42NjFzNi4zOTctMS4yMjEsOC44MzktMy42NjFsNTguNjYxLTU4LjY2MXYyMTMuNjU0YzAsNi45MDMsNS41OTcsMTIuNSwxMi41LDEyLjUgICBjNi45MDEsMCwxMi41LTUuNTk3LDEyLjUtMTIuNVY0Mi42NzdsNTguNjYxLDU4LjY1OWM0Ljg4Myw0Ljg4MSwxMi43OTcsNC44ODEsMTcuNjc4LDAgICBDMjI4LjEzNyw5Ni40NTUsMjI4LjEzNyw4OC41NDEsMjIzLjI1NSw4My42NTl6IiBmaWxsPSIjMzE5YmVmIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
  background-repeat: no-repeat;
  background-size: 10px;
  background-position-x: center;
  background-position-y: center;
}

.button-animate-purple .btn-icon-purple::after {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  transition: all 0.3s linear;
}

.btn-activate-purple .btn-icon-purple::after {
  width: 24px;
  height: 24px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDI2IDI2IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNiAyNiIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCI+CiAgPHBhdGggZD0ibS4zLDE0Yy0wLjItMC4yLTAuMy0wLjUtMC4zLTAuN3MwLjEtMC41IDAuMy0wLjdsMS40LTEuNGMwLjQtMC40IDEtMC40IDEuNCwwbC4xLC4xIDUuNSw1LjljMC4yLDAuMiAwLjUsMC4yIDAuNywwbDEzLjQtMTMuOWgwLjF2LTguODgxNzhlLTE2YzAuNC0wLjQgMS0wLjQgMS40LDBsMS40LDEuNGMwLjQsMC40IDAuNCwxIDAsMS40bDAsMC0xNiwxNi42Yy0wLjIsMC4yLTAuNCwwLjMtMC43LDAuMy0wLjMsMC0wLjUtMC4xLTAuNy0wLjNsLTcuOC04LjQtLjItLjN6IiBmaWxsPSIjMmY4OWQxIi8+Cjwvc3ZnPgo=);
  background-repeat: no-repeat;
  background-size: 8px;
  background-position-x: center;
  background-position-y: 34px;
}

.btn-activate-purple:hover {
  background-color: #9375fb;
}

.btn-activate-purple:hover .btn-icon-purple {
  border-color: #9375fb;
}

.btn-activate-purple:hover .btn-icon-purple::before {
  background-position-y: -34px;
}

.btn-activate-purple:hover .btn-icon-purple::after {
  background-position-y: center;
}

.btn-waiting-purple {
  background-color: #9375fb;
}

.btn-waiting-purple .btn-icon-purple {
  background-color: transparent;
}

.btn-waiting-purple .btn-icon-purple::after {
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: rotation infinite linear 0.5s;
  transition: none;
  border-top: 2px solid transparent;
  border-left: 2px solid #fff;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  z-index: 0;
}

.btn-activated-purple {
  background-color: #9375fb;
  box-shadow: 0 5px 20px 0 rgba(147, 117, 251, 0.3);
}

.btn-activated-purple .btn-icon-purple::after {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDI2IDI2IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAyNiAyNiIgd2lkdGg9IjI0cHgiIGhlaWdodD0iMjRweCI+CiAgPHBhdGggZD0ibS4zLDE0Yy0wLjItMC4yLTAuMy0wLjUtMC4zLTAuN3MwLjEtMC41IDAuMy0wLjdsMS40LTEuNGMwLjQtMC40IDEtMC40IDEuNCwwbC4xLC4xIDUuNSw1LjljMC4yLDAuMiAwLjUsMC4yIDAuNywwbDEzLjQtMTMuOWgwLjF2LTguODgxNzhlLTE2YzAuNC0wLjQgMS0wLjQgMS40LDBsMS40LDEuNGMwLjQsMC40IDAuNCwxIDAsMS40bDAsMC0xNiwxNi42Yy0wLjIsMC4yLTAuNCwwLjMtMC43LDAuMy0wLjMsMC0wLjUtMC4xLTAuNy0wLjNsLTcuOC04LjQtLjItLjN6IiBmaWxsPSIjNDRjYzcxIi8+Cjwvc3ZnPgo=);
  width: 24px;
  height: 24px;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  animation: activated 0.3s linear 1;
}

.btn-text-purple {
  position: relative;
}

.btn-text-purple::before {
  content: attr(data-after);
  position: absolute;
  top: -27px;
  z-index: -1;
  color: #fff;
  transition: all 0.2s linear;
}

.btn-text-purple::after {
  content: attr(data-wait);
  position: absolute;
  top: 2px;
  left: 0;
  z-index: -1;
  color: #fff;
  transition: all 0.2s linear;
}

.btn-waiting-purple .btn-text-purple {
  color: transparent;
}

.btn-waiting-purple .btn-text-purple::after {
  top: -15px;
  z-index: 1;
}

.btn-activated-purple .btn-text-purple::before {
  top: -15px;
  z-index: 1;
}

.btn-activated-purple .btn-text-purple {
  color: transparent;
}

@keyframes rotation {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes activated {
  0% {
    background-position-y: 34px;
  }

  100% {
    background-position-y: center;
  }
}

/*======== BUTTON ANIMATE END ========*/
